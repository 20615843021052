<script setup lang="ts">
import { storeToRefs } from 'pinia'
import ButtonGroup from '~/components/UI/ButtonGroup.vue'
import CitySelectField from '~/components/UI/CitySelectField.vue'
import SelectField from '~/components/UI/SelectField.vue'
import { Client, InsuranceSubCategory, type InsuranceRateType } from '@/types'
import Button from '~/components/UI/Button.vue'

defineProps<{
  options: InsuranceRateType[],
}>()

const emit = defineEmits(['update', 'submit'])

const { supplierList } = storeToRefs(useBrandStore())

const {
  city,
  clientType,
  insuranceSubCategory,
  supplier,
} = storeToRefs(useFilterStore())
const {
  setCity,
  setClientType,
  setInsuranceSubCategory,
  setProvince,
  setSupplier,
  resetFilters,
} = useFilterStore()

const onCity = (value: any) => {
  setCity(value.nome)
  setProvince(value.provincia.nome)
}
</script>

<template>
  <form class="isolate flex flex-1 flex-col gap-4">
    <ButtonGroup id="category" :value="insuranceSubCategory" :options="[
      { id: 1, label: 'Tutti', value: InsuranceSubCategory.ALL },
      { id: 2, label: 'Casa', value: InsuranceSubCategory.HOME },
      { id: 3, label: 'Personale', value: InsuranceSubCategory.PERSONAL },
      { id: 4, label: 'Extra', value: InsuranceSubCategory.EXTRA },
    ]" @input="setInsuranceSubCategory">
      <template #label> Tipo di assicurazione </template>
    </ButtonGroup>

    <SelectField id="supplier" :value="supplier" :options="supplierList" @input="setSupplier">

      <template #label> Fornitore </template>
    </SelectField>

    <ButtonGroup id="clientType" :value="clientType" :options="[
      { id: 1, label: 'Tutti', value: Client.ALL },
      { id: 2, label: 'Privato', value: Client.PRIVATE },
      { id: 3, label: 'Business', value: Client.BUSINESS },
    ]" @input="setClientType">

      <template #label>
        <div class="flex items-center gap-2">
          <span>Tipo cliente</span>
        </div>
      </template>
    </ButtonGroup>

    <CitySelectField id="city" :value="city" :query="unref(city)" @input="onCity" />

    <div class="sm:hidden flex flex-col my-4">
      <Button class="h-10" @click="$emit('submit')">
        Applica
      </Button>
    </div>
    <div class="flex flex-col my-4">
      <Button class="h-10" @click="resetFilters">
        Reimposta filtri
      </Button>
    </div>
  </form>
</template>
